import { GithubComItsreverReturnsApiPkgDomainRefundmethodsShippingCosts as RestShippingCosts } from '@itsrever/returns-api-types'
import DeliveryIcon from '@/assets/images/icons/DeliveryIcon'
import { formatStringPrice } from '@/utils'
import { useTranslation } from 'react-i18next'

interface ShippingCostsProps {
    shippingCosts?: RestShippingCosts
    isInstantMethod?: boolean
}
export const ShippingCosts: React.FC<ShippingCostsProps> = ({
    shippingCosts,
    isInstantMethod = false
}) => {
    const { t } = useTranslation()
    const { i18n } = useTranslation()
    const lang = i18n.language
    const freeCosts = parseFloat(shippingCosts?.max?.amount ?? '0') === 0
    const freeAvailable = parseFloat(shippingCosts?.min?.amount ?? '0') === 0

    if (freeCosts || (freeAvailable && isInstantMethod)) {
        return <></>
    }
    const equals =
        parseFloat(shippingCosts?.min?.amount ?? '0') ===
        parseFloat(shippingCosts?.max?.amount ?? '0')

    const minShippingCost = formatStringPrice(
        shippingCosts?.min?.amount,
        shippingCosts?.min?.currency,
        lang
    )
    const maxShippingCost = formatStringPrice(
        shippingCosts?.max?.amount,
        shippingCosts?.max?.currency,
        lang
    )

    return (
        <>
            <div>
                <span className="flex items-center text-xs text-grey-2">
                    {t('refund_card.shipping_costs')}{' '}
                    {equals ? (
                        <>{minShippingCost}</>
                    ) : (
                        <>
                            {freeAvailable ? (
                                <>
                                    {t('refund_card.up_to')} {maxShippingCost}{' '}
                                </>
                            ) : (
                                <>
                                    {t('refund_card.from')} {minShippingCost}{' '}
                                    {t('refund_card.up_to')} {maxShippingCost}
                                </>
                            )}
                        </>
                    )}
                    <DeliveryIcon />
                </span>
            </div>
        </>
    )
}
export default ShippingCosts
